import { Controller } from "@hotwired/stimulus";
import FileInput from "@uppy/file-input";
import ProgressBar from "@uppy/progress-bar";
import Informer from "@uppy/informer";
import ThumbnailGenerator from "@uppy/thumbnail-generator";
import { uppyInstance, uploadedFileData } from "../uppy";
// import { Uppy } from "@uppy/core";

export default class extends Controller {
  static targets = [
    "input",
    "filePreview",
    "filePreviewPlaceholder",
    "fileUpload",
    "informer",
    "progressBar",
    "removeFileButton",
    "result",
    "thumbnailPreview",
    "thumbnailPreviewPlaceholder",
  ];
  static values = { types: Array, server: String, buttonText: String };

  connect() {
    console.log("SINGLE UPLOAD CONTROLLER CONNECTED::::::::");
    this.inputTarget.classList.add("d-none");
    this.uppy = this.createUppy();
  }

  removeFile(event) {
    event.preventDefault();
    console.log("REMOVE FILE EVENT TRIGGERED:::::: ", this.hasFilePreviewTarget, this.hasThumbnailPreviewTarget);
    const triggerElement = event.currentTarget;
    console.log("REMOVE FILE EVENT TRIGGERED:::::: ", triggerElement);

    if (this.hasFilePreviewTarget) {
      console.log("hasFilePreviewTarget:::::: ", this.hasFileUploadTarget);
      this.filePreviewTarget.textContent = "";
      this.filePreviewPlaceholderTarget.classList.remove("hidden");
      this.fileUploadTarget.classList.add("hidden");
    }

    if (this.hasThumbnailPreviewTarget) {
      this.thumbnailPreviewTarget.src = "";
      this.thumbnailPreviewPlaceholderTarget.classList.remove("hidden");
    }

    this.updateFileSelectorButtonText("Add");

    const destroyField = this.element.querySelector("input[name*='[_destroy]']");
    if (destroyField) {
      destroyField.value = "1";
    }
    triggerElement.classList.toggle("hidden");
  }

  updateFileSelectorButtonText(newFirstWord) {
    // Select the button with the class `.uppy-FileInput-btn` within this.element
    const button = this.element.querySelector(".uppy-FileInput-btn");

    if (button) {
      // Get the button's current text content
      const buttonText = button.textContent;

      // Split the text into parts on the first space
      const [_, ...rest] = buttonText.split(" ");

      // Replace the first word with the new word and reassemble the text
      const newText = `${newFirstWord} ${rest.join(" ")}`;

      // Update the button's text content
      button.textContent = newText;
    } else {
      console.warn("Button with class `.uppy-FileInput-btn` not found.");
    }
  }

  createUppy() {
    const uppy = uppyInstance({
      id: this.inputTarget.id,
      types: this.typesValue,
      server: this.serverValue,
    })
      .use(FileInput, {
        target: this.inputTarget.parentNode,
        locale: { strings: { chooseFiles: this.buttonTextValue } },
      })
      .use(Informer, {
        target: this.informerTarget,
      })
      .use(ProgressBar, {
        target: this.progressBarTarget,
      })
      .use(ThumbnailGenerator, {
        thumbnailWidth: 600,
      });

    uppy.on("upload-success", (file, response) => {
      // set hidden field value to the uploaded file data so that it's submitted with the form as the attachment
      const uploadedData = uploadedFileData(file, response, this.serverValue);
      this.resultTarget.value = uploadedData;
      console.log("UPLOAD SUCCESS::::::::: ", uploadedData, file.name);
      if (this.hasFilePreviewTarget) {
        this.filePreviewTarget.textContent = file.name;
        this.filePreviewPlaceholderTarget.classList.add("hidden");
        this.fileUploadTarget.classList.remove("hidden");
      }
    });

    uppy.on("thumbnail:generated", (file, preview) => {
      this.thumbnailPreviewTarget.src = preview;
      this.updateFileSelectorButtonText("Change");

      this.removeFileButtonTarget.classList.remove("hidden");
      this.thumbnailPreviewPlaceholderTarget.classList.add("hidden");
      console.log("HAVE REMOVE FILE BUTTON TARGET:::::: ", this.hasRemoveFileButtonTarget);

      console.log("THUMBNAIL GENERATED::::::::: ", file, preview);
    });

    uppy.on("upload-error", function (file, error) {
      console.log("UPLOAD ERROR:::: ", error);
    });

    return uppy;
  }
}
