import { Controller } from "@hotwired/stimulus";
import EditorJS from "@editorjs/editorjs";
import Header from "@editorjs/header";
import List from "@editorjs/list";

export default class extends Controller {
  static targets = ["editorContainer", "hiddenInput"];
  static values = {
    jsonData: String,
  };
  connect() {
    this.observer = new MutationObserver(this.initializeEditors.bind(this));
    this.observer.observe(document.body, {
      childList: true,
      subtree: true,
    });

    this.initializeEditors();
  }

  disconnect() {
    this.observer.disconnect();
  }

  initializeEditors() {
    const uninitialized = document.querySelectorAll(".editor:not(.editor-initialized)");
    uninitialized.forEach(this.initializeEditor.bind(this));
  }

  initializeEditor(editorElement) {
    const hiddenInput = editorElement.nextElementSibling;

    console.log("INITIALISE EDITOR::::::: ", JSON.parse(this.jsonDataValue));
    if (!hiddenInput) return;

    const editor = new EditorJS({
      holder: editorElement.id,
      tools: {
        header: Header,
        list: List,
      },
      onChange: async () => {
        const content = await editor.save();
        hiddenInput.value = JSON.stringify(content);
      },
      minHeight: 300,
      placeholder: "Type text or paste link",
      // Custom placeholder for new blocks
      blockStyles: {
        paragraph: {
          placeholder: "Type text or paste link",
        },
      },
      data: JSON.parse(this.jsonDataValue),
    });

    editorElement.classList.add("editor-initialized");
  }
}
