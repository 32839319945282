import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  initialize() {
    this.clickOutsideHandlers = new Map();
  }

  toggle(event) {
    event.stopPropagation();
    const triggerElement = event.currentTarget;
    const toggleIdentifiers = triggerElement.dataset.toggle.split(",").map((id) => id.trim());
    const groupName = triggerElement.dataset.toggleGroup;
    const shouldReset = triggerElement.dataset.toggleReset === "true";

    if (groupName) {
      this.hideOtherGroupTriggers(groupName, triggerElement);
    }

    const firstElement = document.querySelector(`[data-toggleable-id="${toggleIdentifiers[0]}"]`);
    const isHidden = firstElement.classList.contains("hidden");
    const elements = this.getAllToggleElements(toggleIdentifiers);

    this.toggleElements(elements);

    if (shouldReset) {
      // Disable the trigger element and change cursor
      triggerElement.disabled = true;
      triggerElement.style.cursor = "default";

      // Reset after 5 seconds
      setTimeout(() => {
        this.toggleElements(elements);
        triggerElement.disabled = false;
        triggerElement.style.cursor = ""; // Revert to original cursor
      }, 5000);
    }

    if (isHidden && triggerElement.dataset.revealableCloseOnClickOutside === "true") {
      const handler = this.createClickOutsideHandler(triggerElement);
      this.clickOutsideHandlers.set(triggerElement, handler);
      document.addEventListener("click", handler);
    } else if (!isHidden && this.clickOutsideHandlers.has(triggerElement)) {
      const handler = this.clickOutsideHandlers.get(triggerElement);
      document.removeEventListener("click", handler);
      this.clickOutsideHandlers.delete(triggerElement);
    }
  }

  hideOtherGroupTriggers(groupName, currentTrigger) {
    const groupTriggers = document.querySelectorAll(`[data-toggle-group="${groupName}"]`);

    groupTriggers.forEach((trigger) => {
      if (trigger !== currentTrigger && !this.isHidden(trigger)) {
        const toggleIds = trigger.dataset.toggle.split(",").map((id) => id.trim());
        const elements = this.getAllToggleElements(toggleIds);

        this.toggleElements(elements);

        if (this.clickOutsideHandlers.has(trigger)) {
          const handler = this.clickOutsideHandlers.get(trigger);
          document.removeEventListener("click", handler);
          this.clickOutsideHandlers.delete(trigger);
        }
      }
    });
  }

  isHidden(triggerElement) {
    console.log("TRIGGER ELEMENT DATASET::::: ", triggerElement, triggerElement.dataset);
    const toggleIds = triggerElement.dataset.toggle.split(",").map((id) => id.trim());
    const firstElement = document.querySelector(`[data-toggleable-id="${toggleIds[0]}"]`);
    return firstElement.classList.contains("hidden");
  }

  getAllToggleElements(identifiers) {
    return identifiers.flatMap((identifier) => Array.from(document.querySelectorAll(`[data-toggleable-id="${identifier}"]`)));
  }

  toggleElements(elements) {
    elements.forEach((element) => {
      element.classList.toggle("hidden");
    });
  }

  createClickOutsideHandler(triggerElement) {
    return (event) => {
      if (!triggerElement.contains(event.target)) {
        const toggleIdentifiers = triggerElement.dataset.toggle.split(",").map((id) => id.trim());
        const elements = this.getAllToggleElements(toggleIdentifiers);

        this.toggleElements(elements);
        document.removeEventListener("click", this.clickOutsideHandlers.get(triggerElement));
        this.clickOutsideHandlers.delete(triggerElement);
      }
    };
  }

  disconnect() {
    this.clickOutsideHandlers.forEach((handler) => {
      document.removeEventListener("click", handler);
    });
    this.clickOutsideHandlers.clear();
  }
}
