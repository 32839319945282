// app/javascript/controllers/share_button_controller.js
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["button"];
  static values = {
    title: String,
    url: String,
  };

  connect() {
    if (navigator.share) {
      this.buttonTarget.classList.remove("hidden");
    }
  }

  async share() {
    try {
      await navigator.share({
        title: this.titleValue,
        url: this.urlValue,
      });
    } catch (err) {
      if (err.name !== "AbortError") {
        console.error("Error sharing:", err);
      }
    }
  }
}
