import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["template", "list", "item"];
  static values = {
    maxItems: Number,
    currentId: Number,
    labelText: { type: String, default: "Item" },
  };

  connect() {
    this.updateAddButtonVisibility();
    this.updateItemNumbers();
  }

  add(event) {
    event.preventDefault();

    console.log("[Nested-Forms#add] visibleItemCount", this.visibleItemCount);
    console.log("[Nested-Forms#add] itemTargets", this.itemTargets);
    console.log("[Nested-Forms#add] itemTargets count", this.itemTargets.length);
    console.log("[Nested-Forms#add] hasReachedMaxItems", this.hasReachedMaxItems);

    if (this.hasReachedMaxItems) return;

    const content = this.templateTarget.content.cloneNode(true);

    // Replace NEW_RECORD placeholder with timestamp-based ID
    const timestamp = new Date().getTime();
    content.querySelectorAll('[name*="NEW_RECORD"]').forEach((element) => {
      element.name = element.name.replace(/NEW_RECORD/g, timestamp);
    });

    this.listTarget.appendChild(content);

    console.log("[Nested-Forms#add] listTarget", this.listTarget);

    this.updateAddButtonVisibility();
    this.updateItemNumbers();
    this.dispatch("added", { detail: { list: content } });
  }

  remove(event) {
    event.preventDefault();
    const item = event.target.closest("[data-nested-form-target='item']");

    // If it's an existing record, mark it for destruction
    const destroyField = item.querySelector("input[name*='[_destroy]']");
    if (destroyField) {
      destroyField.value = "1";
      item.style.display = "none";
    } else {
      // If it's a new record, just remove it from the DOM
      item.remove();
    }

    this.updateAddButtonVisibility();
    this.updateItemNumbers();
    this.dispatch("removed", { detail: { item: item } });
  }

  updateItemNumbers() {
    let visibleIndex = 0;
    this.itemTargets.forEach((item) => {
      // Skip hidden (destroyed) items
      if (item.style.display === "none") return;

      const label = item.querySelector('[data-nested-form-target="itemLabel"]');
      if (label) {
        label.textContent = `${this.labelTextValue} ${visibleIndex + 1}`;
      }
      visibleIndex++;
    });
  }

  updateAddButtonVisibility() {
    // ensures we only look for the button among direct children of this.element. This prevents the selector from finding nested buttons that might be inside child elements.
    const addButton = this.element.querySelector(":scope > [data-action*='nested-form#add']");

    if (addButton) {
      addButton.style.display = this.hasReachedMaxItems ? "none" : "block";
    }
  }

  get visibleItemCount() {
    return this.itemTargets.filter((item) => {
      const destroyField = item.querySelector("input[name*='[_destroy]']");
      return !destroyField || destroyField.value !== "1";
    }).length;
  }

  get hasReachedMaxItems() {
    return this.hasMaxItemsValue && this.visibleItemCount >= this.maxItemsValue;
  }
}
