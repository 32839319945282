import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["copyButton", "copyValue", "copyIcon", "copyText"];

  copy(event) {
    event.stopPropagation();
    const triggerElement = event.currentTarget;
    navigator.clipboard.writeText(this.copyValueTarget.value);
    triggerElement.classList.add("!border-green-600");
    // Reset after 5 seconds
    setTimeout(() => {
      triggerElement.classList.remove("!border-green-600");
    }, 5000);
  }
}
